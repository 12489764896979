
import MainComponent from './components/MainComponent';
import Header from './components/Header';
import Footer from './components/Footer';
import CompatibilityImg from './assets/phone_img.png';
import MeaningfulImg from './assets/Group_9071.svg';
import InnovativeImg from './assets/Group_9075.svg';

function App() {
  return (
    <>
    <Header/>
    <MainComponent title="Unique Approach to Dating" subtitle="Meaningful Connections" imgPath={MeaningfulImg} text="Custom features that prioritize real interactions.|Authentic connections." gradientStart="#8366fc" gradientEnd="#ec484d"/>
    <MainComponent title="Unique Approach to Dating" subtitle="AI-Driven Compatibility Checks" imgPath={CompatibilityImg} text="We ensure every match is tested for true compatibility." gradientStart="#8466FC" gradientEnd="#00CA84"/>
    <MainComponent title="Unique Approach to Dating" subtitle="Innovative Matching Algorithms" imgPath={InnovativeImg} text="We foster genuine interactions with intuitive app design." gradientStart="#8466FC" gradientEnd="#2807AA"/>
    <Footer/>
    </>
  );
}

export default App;
