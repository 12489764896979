import icon from './../assets/instagram_icon.svg';

const Footer = (link)=> {
    return (
    <div className="w-full flex flex-row p-4 justify-between bg-[#2807AA] text-white max-md:flex-col gap-3">
        <div className=" font-bold font-['Urbanist'] text-xl"> Unblurred </div>
        <div className="flex justify-evenl items-center">
            <a href="https://www.instagram.com/unblurred.app?igsh=N2x1amVqcnpza2Jw" target="_blank" rel="noopener noreferrer">
                <img className="w-6 h-6 mr-4 cursor-pointer" src={icon} alt="instagram icon"/>
            </a>
            <p>Be the first to know. Follow us on instagram!</p>
        </div>
    </div>
    );
  }
  
  export default Footer;
  