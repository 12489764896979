
const MainComponent = (props)=> {
  return (
    <div className={`w-full min-h-screen flex relative items-center max-md: px-6`}
    style={{
        background: `linear-gradient(to right, ${props.gradientStart}, ${props.gradientEnd})`}}
    >
      <div className="w-full absolute z-10 flex flex-col order-2 top-0 left-0 overflow-hidden flex-shrink-0">
        <svg xmlns="http://www.w3.org/2000/svg" width="1129" height="509" viewBox="0 0 1129 509" fill="none">
          <path opacity="0.1" d="M355 489C173.671 439.288 -221.902 91.7866 -290.998 112.5C-315.537 -24.9456 233.446 -139.866 639.5 -144.5C1147.07 -150.293 1074.48 68.7899 1026.25 93.0003C962.501 125 917.053 114.323 826.001 147.5C734.948 180.676 756.968 265.578 1026.25 307.18C1295.53 348.782 952.633 403.55 910.013 423.562C867.392 443.573 581.663 551.14 355 489Z" fill="#EEE9F5" />
        </svg>
      </div>
      <div className="w-full z-20 flex flex-col pt-14 pb-7 order-1">
        <div className="h-[104px] flex-col justify-center items-center gap-4 flex">
          <div className="self-stretch text-center text-white text-5xl font-bold font-['Urbanist'] leading-[56px]">{props.title}</div>
          <div className="self-stretch text-center text-white text-2xl font-bold font-['Urbanist'] leading-loose">{props.subtitle}</div>
        </div>
        <div className="w-full py-10 flex flex-col justify-center items-center">
          <img className="max-md: mt-14" src={props.imgPath} width="547px" alt=""></img>
        </div>
        <div className="w-full flex flex-col justify-center items-center">
            {props.text.split("|").map((line, index) => (
                <div className="text-center text-white text-[28px] font-normal font-['Urbanist'] leading-9">{line}</div>
            ))}
        </div>
      </div>
      <div className="w-full z-10 absolute h-[570px] flex overflow-hidden flex-shrink-0 flex-col order-2 bottom-0 right-0">
        <svg xmlns="http://www.w3.org/2000/svg" width="1920" height="570" viewBox="0 0 1920 570" fill="none">
          <path opacity="0.1" d="M123.449 246.216C-670.062 197.768 -784.491 347.852 -742.516 428.95C-692.147 483.016 -538.326 579.877 -326 534.799C-60.5921 478.452 -335.686 397.353 220.314 422.631C776.314 447.908 429.541 549.544 613.582 577.455C797.624 605.365 1434.99 665.399 1465.99 522.16C1496.98 378.922 1711.26 317.56 1827.5 257C1943.74 196.44 2027.56 185.656 2014 92.4456C2000.44 -0.764587 1861.19 42.4175 1628.72 11.8741C1396.24 -18.6694 1128.9 10.8209 1105.65 92.4456C1082.4 174.07 1115.34 306.776 123.449 246.216Z" fill="#EEE9F5" />
        </svg>
      </div>
    </div>
  );
}

export default MainComponent;
