import {useState, useEffect} from 'react';

const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

const Header = ()=> {

    const [isScrolledIntoView, setIsScrolledIntoView] = useState(true);

    useEffect(()=>{
        const handleScroll = ()=>{
            if (window.scrollY > 100) setIsScrolledIntoView(false)
            else setIsScrolledIntoView (true)
        }

    window.addEventListener('scroll', handleScroll)

    return()=> window.removeEventListener ('scroll', handleScroll);},[]);

    return (
      <div className={`w-full flex p-4 font-bold font-['Urbanist'] text-2xl text-[#3f0885] sticky top-0 z-30 ${isScrolledIntoView? 'bg-[#8366fc]' :'bg-transparent'} max-md:bg-[#8366fc]`}
      style={{transition:'background-color 0.3s ease'}}> 
        <span  onClick={scrollToTop} className="cursor-pointer">Unblurred</span> 
      </div> 
    );
  }
  
  export default Header;
  